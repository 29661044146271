import * as React from "react";
import { graphql, Link } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import PageLayout from "../components/page-layout/PageLayout";
import TimeAgo from "../components/typography/TimeAgo";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  useRefinementList,
  Hits,
  Highlight,
  Snippet,
  PoweredBy,
  useSearchBox,
  usePagination,
  useConnector,
  Configure,
} from "react-instantsearch-hooks-web";
import Pagination from "../components/Pagination";
import connectStats from "instantsearch.js/es/connectors/stats/connectStats";
import SEO from "../components/SEO";
import TextHero from "../components/hero/TextHero";
import Title from "../components/typography/Title";
import { SearchIcon } from "@heroicons/react/solid";
import { useConfig } from "../hooks/useConfig";

const Searchbox = () => {
  const { query, refine } = useSearchBox();
  const ref = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleKeyPress = React.useCallback(
    (event) => event.keyCode === 75 && event.metaKey && ref.current?.focus(),
    [ref]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  return (
    <div className="lg:max-w-4xl mx-auto">
      <div className="relative h-12 flex items-center">
        <SearchIcon
          className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <input
          className="block w-full h-full pl-12 rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          type="text"
          placeholder={t("resources:search")}
          aria-label="Search"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          onChange={(e) => refine(e.target.value)}
          value={query}
          ref={ref}
        />
        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
            ⌘K
          </kbd>
        </div>
      </div>
    </div>
  );
};

const KeywordFilters = () => {
  const { t } = useTranslation();
  const {
    items,
    refine,
    canToggleShowMore,
    toggleShowMore,
    isShowingMore,
    searchForItems,
  } = useRefinementList({
    showMore: true,
    attribute: "keywords",
    operator: "or",
    sortBy: ["count:desc", "name:asc"],
  });

  return (
    <div className="pb-6 my-6 border-b border-gray-200">
      <input
        type="text"
        className="block w-full rounded-md h-8 border-1 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder={t("resources:search")}
        onChange={(e) => searchForItems(e.target.value)}
      />
      <fieldset className="space-y-3 mt-6 text-sm font-medium text-gray-700">
        {items.map((keyword, i) => (
          <div key={`kyword-filter-${i}`} className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id={keyword.value}
                name={keyword.value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={() => refine(keyword.value)}
                checked={keyword.isRefined}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor={keyword.value}
                className="font-medium text-gray-700 whitespace-nowrap"
              >
                {keyword.label} ({keyword.count})
              </label>
            </div>
          </div>
        ))}
      </fieldset>
      {canToggleShowMore && (
        <p
          onClick={toggleShowMore}
          className="mt-6 text-sm text-gray-500 cursor-pointer"
        >
          {isShowingMore ? "Show less..." : "Show more..."}
        </p>
      )}
    </div>
  );
};

const PageHit = ({ hit }) => {
  return (
    <div className="lg:px-4 py-6 hover:bg-gray-50">
      <div className="flex space-x-2 items-center mb-2 text-sm text-gray-500">
        <img
          src={hit.author.image}
          className="h-6 w-6 rounded-full mr-2 shadow-sm"
        />
        <Highlight
          attribute="author.name"
          hit={hit}
          classNames={{
            highlighted: "bg-primary",
          }}
        />
        <span className="mx-1">&middot;</span>
        <TimeAgo date={hit.date_modified} className="text-sm text-gray-500" />
      </div>

      {/* @ts-ignore */}
      <Link to={`/risorse/${hit.slug}`}>
        <Title type="h4">
          <Highlight
            attribute="title"
            hit={hit}
            classNames={{
              highlighted: "bg-primary",
            }}
          />
        </Title>
        <hr className="w-2/12 -mt-1 mb-1 border-primary" />
        <Snippet
          attribute="content"
          hit={hit}
          classNames={{
            root: "leading-3 text-gray-700 text-sm text-justify",
            highlighted: "bg-primary",
          }}
        />
      </Link>

      <div className="flex mt-3 flex-wrap">
        {hit.links.map((link, i) => (
          <a
            key={`link-${i}`}
            href={link}
            target="_blank"
            className="text-sm text-gray-400 whitespace-nowrap mr-2 max-w-[250px] text-ellipsis overflow-hidden hover:underline hover:text-gray-600"
          >
            {link}
          </a>
        ))}
      </div>
    </div>
  );
};

const HitsPagination = () => {
  const { pages, currentRefinement, refine } = usePagination();

  return (
    <Pagination
      totalPageCount={pages.length}
      currentPage={currentRefinement}
      onPageChange={(page: number) => refine(page)}
    />
  );
};

const HitsStats = () => {
  const { nbHits, processingTimeMS } = useConnector(connectStats)!;
  const { t } = useTranslation();
  return (
    <p className="text-md text-gray-500 mt-4">
      {t("resources:results", {
        count: nbHits as number,
      })}{" "}
      {t("resources:resultTime", { time: processingTimeMS as number })}
    </p>
  );
};

const ResourcesPage = () => {
  const { t } = useTranslation();
  const { algolia } = useConfig();

  const searchClient = React.useMemo(
    () => algoliasearch(algolia.appId, algolia.searchKey),
    []
  );

  return (
    <PageLayout pageName="resources" headerBg="gray-100">
      <SEO
        titleI18nKey={"resources:meta.title"}
        descriptionI18nKey={"resources:meta.description"}
        datePublished={new Date("2022-12-01T08:00:00Z")}
      />

      <InstantSearch searchClient={searchClient} indexName={algolia.index}>
        <TextHero
          tag={t("resources:hero.tag")}
          title={
            <Trans
              i18nKey="resources:hero.title"
              components={{ ol: <span className="text-ol-gradient" /> }}
            />
          }
          description={t("resources:hero.content")}
          cta={
            <div className="w-full mt-12">
              <Searchbox />
              <HitsStats />
            </div>
          }
        />
        <section className="container px-5 my-24 mx-auto">
          <div className="lg:px-7 flex flex-col md:flex-row md:space-x-12">
            <div className="w-full md:w-56 shrink-0 mb-12">
              <Title large>{t("resources:categories")}</Title>
              <KeywordFilters />
              <PoweredBy className="w-1/2 md:w-5/6" />
            </div>
            <div className="w-full">
              <Hits
                className="Hits"
                hitComponent={PageHit}
                classNames={{
                  root: "overflow-hidden bg-white",
                  list: "divide-y divide-gray-200",
                }}
              />
              <HitsPagination />
            </div>
          </div>
        </section>
      </InstantSearch>
    </PageLayout>
  );
};

export default ResourcesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
